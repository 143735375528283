import React, { useEffect } from "react";
import { PropTypes } from "prop-types";

const GoogleAccountsInitializer = ({ loggedOut, onCredentialResponse }) => {
  useEffect(
    () => {
      if (loggedOut && window.google) {
        console.log("Initializing Google Accounts...");
        window.google.accounts.id.initialize({
          client_id: "962641865525-qk3eqej1shfi589ahq1enclp2i5soen0.apps.googleusercontent.com",
          callback: onCredentialResponse,
          itp_support: true
        });
        window.google.accounts.id.prompt();
      }
    },
    [loggedOut]
  ); // Re-run when loggedOut changes

  return <div />; // No UI for this component
};

GoogleAccountsInitializer.propTypes = {
  loggedOut: PropTypes.bool,
  onCredentialResponse: PropTypes.func
};
export default GoogleAccountsInitializer;
