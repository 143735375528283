import React from "react";
import PropTypes from "prop-types";
import FocusedImage from "../../utils/focused-image";
import ImageResize from "./imageResize";

const AssettypeImage = props => {
  const { slug, metadata, aspectRatio, imageCDN = "media.vikatan.com", imgParams, className, alt } = props;

  const focusedImagePath = ({ imgParams, slug, metadata, aspectRatio }) => {
    const imgAspectRatio = aspectRatio && aspectRatio.map(el => Number(el));
    const newOpts = Object.assign({ q: 75 }, imgParams);
    const path = metadata ? new FocusedImage(slug, metadata).path(imgAspectRatio, newOpts) : `${slug}?q=60`;
    return `https://${imageCDN}/${path}`;
  };

  const imagesData = [
    {
      srcBase: `${focusedImagePath({ imgParams, slug, metadata, aspectRatio })}`,
      className,
      alt
    }
  ];
  return <ImageResize images={imagesData} />;
};

AssettypeImage.propTypes = {
  slug: PropTypes.string.isRequired,
  metadata: PropTypes.object.isRequired,
  aspectRatio: PropTypes.string.isRequired,
  imageCDN: PropTypes.string.isRequired,
  imgParams: PropTypes.object,
  reactTag: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string
};

AssettypeImage.defaultProps = {
  imgParams: {},
  className: "",
  alt: ""
};

export default AssettypeImage;
