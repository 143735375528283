/* eslint-disable no-undef */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Media from "react-media";
import { get } from "lodash";
import Icon from "../../../atoms/icon";
import MobileLogin from "../../../../../isomorphic/components/rows/header/user-login/login-mobile";
import styles from "./styles.m.css";
import { DeepBI } from "../../../../integrations";
import assetify from "@quintype/framework/assetify";
import loader from "../../../../../assets/images/loader.gif";

import Cookies from "universal-cookie";
const cookies = new Cookies();

function UserLogin({ user, vikatanHosts = {} }) {
  const loggedOut = get(user, ["loggedOut"], true);
  const [IsOpened, setIsOpened] = useState(false);
  const [Isloading, setIsloading] = useState(false);

  const loginOptionPopup = () => {
    DeepBI.PingLoginevents("Login_widget_view");
    if (global.webengage && global.webengage.track) {
      global.webengage.track("Login_widget_view", {});
    }
    setIsOpened(prevIsOpened => !prevIsOpened);
  };

  const handleMobileLoginClose = isPopupClosed => {
    setIsOpened(isPopupClosed);
  };

  const handleLogout = () => {
    setIsloading(true);

    const cookiesToRemove = [
      "vikatan[auth_id]",
      "vikatan[auth_token]",
      "vikatan[utype]",
      "vuid",
      "ev-guid",
      "ev_ss",
      "ex_ss",
      "utype"
    ];

    cookiesToRemove.forEach(cookie => {
      cookies.remove(cookie, { path: "/", domain: ".vikatan.com" });
    });

    if (global.webengage && global.webengage.user) {
      global.webengage.user.logout();
    }

    global.localStorage.removeItem("evolok:ev_session");

    setTimeout(() => {
      global.location.reload();
    }, 1000);
  };

  if (!loggedOut) {
    return (
      <div className={styles.user}>
        {Isloading && (
          <div className={styles["loader-wrapper"]}>
            <img alt="Vikatan" src={assetify(loader)} className={styles["loader"]} />
          </div>
        )}
        <Media query="(max-width: 767px)">
          {matches =>
            matches ? (
              <Icon type="user-square" className={styles["logout-icon"]} />
            ) : (
              <span className={`hidden-mobile ${styles.logout}`}>
                <span className={styles["reader-text"]}>My Account</span>
                <Icon type="downarrow" className={styles["login-arrow"]} />
              </span>
            )
          }
        </Media>
        {user && (
          <div className={styles["myaccount-dropdown-wrap"]}>
            <ul className={styles["myaccount-dropdown"]}>
              <div className={`${styles["mbl-dd-txt"]} hidden-desktop`}>
                <span className={styles["reader-text"]}>My Account</span>
                <Icon type="downarrow" className={styles["login-arrow"]} />
              </div>
              <li className={styles["myaccount-item"]}>
                <a href={`${vikatanHosts.userProfile}`} className={styles["myaccount-action-item"]}>
                  Your Profile
                </a>
              </li>
              <li className={styles["myaccount-item"]}>
                <a
                  // href={global.location && `${vikatanHosts.logout}?redirect_url=${global.location.href}`}
                  onClick={handleLogout}
                  className={styles["myaccount-action-item"]}
                >
                  Sign Out
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <a
          aria-label="User Login"
          // href={global.location && `${"https://login.vikatan.com"}?redirect_url=${global.location.href}`}
          className={styles.user}
          onClick={loginOptionPopup}
        >
          <Icon className={`${styles["login"]} hidden-desktop`} type={"user-square"} />{" "}
          <span className={`hidden-mobile  ${styles.login}`}>
            <span className={styles["login-text"]}>Login</span>
          </span>
        </a>
        {IsOpened && <MobileLogin onClose={handleMobileLoginClose} />}
      </div>
    );
  }
}

UserLogin.propTypes = {
  user: PropTypes.object,
  vikatanHosts: PropTypes.shape({
    login: PropTypes.string,
    subscription: PropTypes.string,
    userProfile: PropTypes.string,
    logout: PropTypes.string
  })
};

export default UserLogin;
