import React from "react";
import PropTypes from "prop-types";
import produce from "immer";
import CollectionLink from "../../utils/generate-collection-link";

import BigStoryCardCurve from "../../atoms/big-story-card-curve/index";
import SevenStoryOneCollectionStoryStack from "../../atoms/seven-story-1c-story-stack";

import styles from "./styles.m.css";

import { wrapCollectionLayout } from "@quintype/components";
import { getStoryHeadline } from "../../utils/utils";
import Media from "react-media";
import { PGAdwithAd } from "../ad-free";
import Icon from "../../atoms/icon";
const WrappedStoryStack = wrapCollectionLayout(SevenStoryOneCollectionStoryStack);

const mobileStoryCount = 3;

const desktopEvenStoryCount = 7;

const SevenStory1C = ({ collection, metadata, accentColor, pageType }) => {
  const { items } = collection;

  const adUnitPrefix = pageType === "home-page" ? "HP" : "SP";
  let stacks = items
    .filter(item => item.type === "collection")
    .slice(0, 1)
    .map((collection, index) => {
      collection = produce(collection, draft => {
        draft.items = draft.items
          .filter(({ story = {} }) => getStoryHeadline(story) != null && story.url != null)
          .slice(0, index % 2 === 0 ? desktopEvenStoryCount : desktopEvenStoryCount);
      });
      return (
        <React.Fragment key={collection.id}>
          <CollectionLink collection={collection}>
            <h2 className={styles["sub-collection-headline"]}>
              {collection.name}
              <div className={styles["collection-cta"]}>
                <Icon type={"slider-next"} />
              </div>
            </h2>
          </CollectionLink>
          <WrappedStoryStack bigStory={index % 2 === 0} mobileStoryCount={mobileStoryCount} collection={collection} />

          {index % 2 === 0 && (
            <Media query="(max-width:768px)">
              <PGAdwithAd
                adId={`Vikatan_mobile_mid_${adUnitPrefix}`}
                adSize={[[300, 250], [336, 280]]}
                minHeight={280}
                adDivId="div-ad-desk-3849069-1694280367-0"
                class="d_300_250"
              />
            </Media>
          )}
        </React.Fragment>
      );
    });

  if (!stacks || !stacks.length) {
    return null;
  }

  return (
    <div className={styles["base"]} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`${styles["container-story"]} container`}>
        <div className={styles["stack-grid"]}>{stacks}</div>
      </div>
    </div>
  );
};

const Collection = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.string,
  slug: PropTypes.string,
  readMoreText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(BigStoryCardCurve.propTypes))
});

SevenStory1C.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(Collection)
  }),
  metadata: PropTypes.shape({
    read_more_text: PropTypes.string
  }),
  pageType: PropTypes.string
};

export default SevenStory1C;
