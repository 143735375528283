import React, { useEffect } from "react";
import PropTypes from "prop-types";

const ImageResize = ({ images }) => {
  useEffect(() => {
    const updateImageSources = () => {
      const devicePixelRatio = 1;

      const imgElements = document.querySelectorAll("img[data-src-base]");
      imgElements.forEach(img => {
        const baseSrc = img.getAttribute("data-src-base");
        if (!baseSrc) return;

        const imgWidth = img.clientWidth || img.naturalWidth || 300; // Default width
        const effectiveWidth = Math.ceil(imgWidth * devicePixelRatio + 70);

        const newSrc = `${baseSrc}&w=${effectiveWidth}`;
        if (img.getAttribute("src") !== newSrc) {
          img.setAttribute("src", newSrc);
        }
      });
    };

    // Initialize image sources on mount
    updateImageSources();

    // Debounce resize events
    let resizeTimeout = null;
    const handleResize = () => {
      if (resizeTimeout) clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        updateImageSources();
      }, 150);
    };

    global.addEventListener("resize", handleResize);

    return () => {
      global.removeEventListener("resize", handleResize);
      if (resizeTimeout) clearTimeout(resizeTimeout);
    };
  }, []);

  return (
    <div>
      {images.map((image, index) => (
        <img
          key={index}
          data-src-base={image.srcBase}
          className={image.className ? `qt-image ${image.className}` : "qt-image"}
          alt={image.alt || ""}
        />
      ))}
    </div>
  );
};

ImageResize.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      srcBase: PropTypes.string.isRequired,
      className: PropTypes.string,
      alt: PropTypes.string
    })
  ).isRequired
};

export default ImageResize;
