import React from "react";
import PropTypes from "prop-types";
import CollectionLink from "../../utils/generate-collection-link";
import StoryCardFeaturedBig from "../../atoms/story-card-featured-big";
import StoryCardFeaturedSmall from "../../atoms/story-card-featured-small";
import styles from "./styles.m.css";
import Icon from "../../atoms/icon";

const NewFourStoryHalfFeatured = ({
  collection,
  accentColor,
  authorname = true,
  sectiontag = true,
  newtheme = true
}) => {
  const { name, items } = collection;
  return (
    <div className={`${styles["four-story-half-featured"]}`} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`${styles["four-story-container"]} container`}>
        <CollectionLink collection={collection}>
          <h2 className={`${styles.title}`}>
            {name}
            <span className={styles["collection-cta"]}>
              <Icon type={"slider-next"} />
            </span>
          </h2>
        </CollectionLink>
        <div className={`${styles["four-story-row"]} row`}>
          {items &&
            items.length > 0 && (
              <StoryCardFeaturedBig
                className={styles["story-card-featured-big"]}
                key={items[0].id}
                story={items[0].story}
                authorname={authorname}
                sectiontag={sectiontag}
                newtheme={newtheme}
              />
            )}
          {items &&
            items.length > 1 &&
            items
              .slice(1, 4)
              .map(item => (
                <StoryCardFeaturedSmall
                  className={styles["story-card-featured-small"]}
                  key={item.id}
                  story={item.story}
                  authorname={authorname}
                  sectiontag={sectiontag}
                  newtheme={newtheme}
                />
              ))}
        </div>
      </div>
    </div>
  );
};

NewFourStoryHalfFeatured.propTypes = {
  accentColor: PropTypes.string,
  authorname: PropTypes.string,
  sectiontag: PropTypes.string,
  newtheme: PropTypes.bool,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardFeaturedBig.propTypes)),
    accentColor: PropTypes.string
  })
};
NewFourStoryHalfFeatured.storyLimit = 4;

export default NewFourStoryHalfFeatured;
