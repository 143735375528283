/* eslint-disable camelcase */
import React, { useState, useRef, useEffect } from "react";
import Icon from "../../../atoms/icon";
import styles from "./styles.m.css";
import { PropTypes } from "prop-types";
import { fetchGenerateOtp } from "../../../../api";
import countryCodes from "./countrycode.json";
import Cookies from "universal-cookie";
import { get } from "lodash";
import assetify from "@quintype/framework/assetify";
import loader from "../../../../../assets/images/loader.gif";
import { DeepBI } from "../../../../integrations";
import { bin2hex, intoVal } from "../../../utils/utils";
import GmailSignin from "./GmailLogin";
import VikatanLoginFooter from "../../../../components/rows/vikatan-login-footer";
import { connect } from "react-redux";

const sign = require("jwt-encode");

const cookies = new Cookies();

const MobileLogin = ({ onClose, Isloginpage = false, jwtKey, onHideLogin }) => {
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const otpInputRefs = useRef([]);
  const [showPopup, setshowPopup] = useState(true);
  const [showMobileEmail, setShowEmailMobileInput] = useState(true);
  const [ShowMobileInput, setShowMobileInput] = useState(false);
  const [ShowEmailInput, setShowEmailInput] = useState(false);
  const [ShowOtp, setShowOtp] = useState(false);
  const [ShowEmailOtp, setShowOtpEmail] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [Isloading, setIsloading] = useState(false);
  const [ShowSuccessLogin, setShowSuccessLogin] = useState(false);
  const [counter, setCounter] = React.useState(60);
  const [ShowEmailwidget, setShowEmailwidget] = useState(false);
  const [NewUser, setNewUser] = useState(false);
  const [inputType, setInputType] = useState("password");
  const mobileNumberInputRef = useRef(null);

  const togglePasswordImage =
    inputType === "password"
      ? "https://gumlet.assettype.com/vikatan/2022-08/89e16775-067c-428a-ac31-7ec75502012a/004_show.png?w=16"
      : "https://gumlet.assettype.com/vikatan/2022-08/e8ee1876-2ef2-40c8-80ee-4939509c052a/005_hidden.png?w=16";
  useEffect(
    () => {
      // Start countdown if `ShowEmailOtp` is true and counter is greater than 0
      if ((ShowEmailOtp || ShowOtp) && counter > 0) {
        setIsDisabled(true); // Disable buttons while counter is running
        const timer = setInterval(() => {
          setCounter(prevCounter => prevCounter - 1);
        }, 1000);

        return () => clearInterval(timer);
      }

      // Enable buttons when the counter reaches zero
      if (counter === 0) {
        setIsDisabled(false);
      }
    },
    [counter, ShowEmailOtp, ShowOtp]
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window && window.location && window.location.search);

    if (urlParams.get("act") === "signout") {
      console.log("Signing out from WebEngage...");
      // Call the WebEngage logout function
      if (global.webengage && global.webengage.user) {
        global.webengage.user.logout();
      } else {
        console.error("WebEngage is not initialized");
      }
    }
  }, []);

  useEffect(
    () => {
      // Focus on the first input element when the component mounts
      if (ShowEmailOtp || ShowOtp) {
        if (otpInputRefs.current) {
          otpInputRefs.current.focus();
        }
      }
    },
    [ShowEmailOtp, ShowOtp]
  );

  useEffect(
    () => {
      const combinedOtp = otp.join("");
      if (combinedOtp.length === 6 && ShowOtp) {
        handleOptVerify();
      } else {
        if (combinedOtp.length === 6 && ShowEmailOtp) {
          handleOptVerifyEmail();
        }
      }
    },
    [otp]
  );

  useEffect(
    () => {
      if (ShowOtp) {
        const autoreadOTP = () => {
          const input = document.querySelector('input[autocomplete="one-time-code"]');
          if (!input) return;

          // eslint-disable-next-line no-undef
          const ac = new AbortController();
          const form = input.closest("form");

          if (form) {
            form.addEventListener("submit", e => {
              ac.abort();
            });
          }

          navigator.credentials
            .get({
              otp: {
                transport: ["sms"]
              },
              signal: ac.signal
            })
            .then(otp => {
              const verification_code = otp.code;
              setOTP(verification_code.split("").slice(0, 6)); // Update OTP state directly

              setTimeout(() => {
                ShowEmailOtp ? handleOptVerifyEmail() : handleOptVerify(); // Assuming submit_otp() submits the form
              }, 1000);
            })
            .catch(err => {
              console.log(err);
            });
        };

        if ("OTPCredential" in window) {
          if (document.readyState !== "loading") {
            autoreadOTP();
          } else {
            document.addEventListener("DOMContentLoaded", autoreadOTP);
          }
        }

        return () => {
          // Cleanup logic if needed
        };
      }
    },
    [ShowOtp]
  );

  function generateJWT(payload, expiresInSeconds = 300) {
    const now = Math.floor(Date.now() / 1000);
    payload.iat = now; // Issued at
    payload.exp = now + expiresInSeconds;

    const secret = jwtKey;
    const jwt = sign(payload, secret);
    return jwt;
  }
  const setErrorWithTimeout = (message, timeout = 4000) => {
    setError(message);

    // Clear error message after timeout milliseconds
    setTimeout(() => {
      setError("");
    }, timeout);
  };

  const handleRedirectOrReload = (delay = 0) => {
    const params = new URLSearchParams(global && global.location && global.location.search);
    const redirectUrl = params.get("redirect_url");
    setTimeout(() => {
      // Check if onHideLogin function is available (i.e., from VikatanLoginPage)
      if (onHideLogin) {
        // If onHideLogin is available, check if redirectUrl exists
        if (redirectUrl) {
          // Redirect to the specified URL
          global.location.href = redirectUrl;
        } else {
          // If no redirectUrl is provided, navigate to the default URL (e.g., Vikatan home)
          global.location.href = "https://www.vikatan.com/";
        }
      } else {
        // If onHideLogin is not available, fallback to this behavior
        if (redirectUrl) {
          // Redirect to the specified URL
          global.location.href = redirectUrl;
        } else {
          // Reload the page if no redirect URL is provided
          global.location.reload();
        }
      }
    }, delay);
  };

  const handleBack = (activeWidget, prevWidget) => {
    setErrorWithTimeout("");
    setOTP(["", "", "", "", "", ""]);
    if (isDisabled) {
      setCounter(60);
    }
    if (activeWidget === "showMobileEmail") {
      setShowEmailMobileInput(true);
      setShowMobileInput(false);
      setShowEmailwidget(false);
    } else if (activeWidget === "ShowMobileInput") {
      setShowMobileInput(true);
      setShowOtp(false);
    } else if (activeWidget === "ShowEmailwidget") {
      setShowEmailwidget(true);
      setShowEmailInput(false);
    } else if (activeWidget === "ShowEmailOtp") {
      setShowEmailInput(true);
      setShowOtpEmail(false);
    } else if (activeWidget === "ShowEmailInput") {
      if (NewUser) {
        setShowEmailwidget(true);
        setShowOtpEmail(false);
        setShowEmailInput(false);
      } else {
        setShowEmailInput(true);
        setShowOtpEmail(false);
      }
    }
  };

  const updateCountryCode = event => {
    let countryCode = event.target.value;
    setCountryCode(countryCode);
    if (mobileNumberInputRef.current) {
      mobileNumberInputRef.current.focus();
    }
  };

  const handleClosePopup = () => {
    setshowPopup(false);
    onClose(false);
  };

  const handleUserMobile = () => {
    DeepBI.PingLoginevents("Click_continue_mobile_no");
    setShowMobileInput(true);
    setShowEmailMobileInput(false);
  };

  const handleUserEmail = () => {
    DeepBI.PingLoginevents("Click_continue_email_id");
    setShowEmailwidget(true);
    setShowEmailMobileInput(false);
  };

  const handleUserEmailOtp = async () => {
    if (validateEmail()) {
      setIsloading(true);

      const body = {
        email_id: email, // Dynamically using the email value
        login_type: "otp"
      };

      try {
        const result = await fetchGenerateOtp(generateJWT(body), "verify-user");

        if (result.status === 1) {
          DeepBI.PingLoginevents("Get_Email_OTP", "success", email);
          setIsloading(false);
          setShowOtpEmail(true);
          setShowEmailInput(false);
          setShowOtp(false);
          if (!isDisabled) {
            setCounter(60);
            setIsDisabled(true);
          }
        } else {
          setIsloading(false);
          DeepBI.PingLoginevents("Get_Email_OTP", "failure", email, null, result.msg);
          setErrorWithTimeout(result.msg);
        }
      } catch (error) {
        console.error("Error generating OTP for email:", error);
        // You can display an error message to the user here
      }
    } else {
      console.error("Invalid email format"); // Handle invalid email case
    }
  };

  const handleSubmitUser = async (login_type = "otp") => {
    // Skip validation if login_type is "whatsapp" or "resend"
    // eslint-disable-next-line camelcase

    if (login_type !== "whatsapp" || login_type !== "resend") {
      if (!mobileNumber || !countryCode) {
        setErrorWithTimeout("Please enter valid phone number.");
        return;
      }
    }
    let isValid = false;
    setIsloading(true);

    if (countryCode === "+91") {
      // India: Must be exactly 10 digits
      isValid = /^\d{10}$/.test(mobileNumber);
    } else {
      // Other countries: Between 4 to 14 digits
      isValid = /^\d{4,14}$/.test(mobileNumber);
    }

    if (!isValid) {
      setIsloading(false);
      setErrorWithTimeout("Please enter a valid phone number.");
      return;
    }

    const otpTypeMap = {
      resend: "Resend_SMS_OTP",
      whatsapp: "Resend_Whatsapp_OTP"
    };
    if (countryCode === "+91") {
      const body = {
        email_id: `${countryCode.replace(/\+/g, "")}${mobileNumber}`, // This ensures dynamic mobile number
        login_type: login_type,
        ...(login_type === "resend" && { type: "resend_otp" }) // Add type if login_type is "resend"
      };

      try {
        const result = await fetchGenerateOtp(generateJWT(body), "verify-user");
        if (result.status === 1) {
          setIsloading(false);
          setShowMobileInput(false);
          setShowOtp(true);
          setShowOtpEmail(false);
          if (!isDisabled) {
            setCounter(60);
            setIsDisabled(true);
          }
          let username = `${countryCode.replace(/\+/g, "")}${mobileNumber}`;

          if (otpTypeMap.hasOwnProperty(login_type)) {
            const eventType = otpTypeMap[login_type];
            DeepBI.PingLoginevents(eventType, "success", username);
          } else {
            DeepBI.PingLoginevents("Send_Mobile_OTP", "success", username);
          }
        } else {
          setIsloading(false);
          setErrorWithTimeout(result.msg);
          let username = `${countryCode.replace(/\+/g, "")}${mobileNumber}`;
          if (otpTypeMap.hasOwnProperty(login_type)) {
            const eventType = otpTypeMap[login_type];
            DeepBI.PingLoginevents(eventType, "failure", username, null, result.msg);
          } else {
            DeepBI.PingLoginevents("Send_Mobile_OTP", "failure", username, null, result.msg);
          }
        }
      } catch (error) {
        console.error("Error generating OTP:", error);
        // You may want to display an error message to the user here
      }
    } else {
      setIsloading(true);
      const body = {
        email_id: `${countryCode.replace(/\+/g, "")}${mobileNumber}`, // This ensures dynamic mobile number
        login_type: "whatsapp"
      };

      let username = `${countryCode.replace(/\+/g, "")}${mobileNumber}`;
      try {
        const result = await fetchGenerateOtp(generateJWT(body), "verify-user");
        if (result.status === 1) {
          setIsloading(false);
          // Handle success flow
          setShowMobileInput(false);
          setShowOtp(true);
          setShowOtpEmail(false);
          if (!isDisabled) {
            setCounter(60);
            setIsDisabled(true);
          }
          if (otpTypeMap.hasOwnProperty(login_type)) {
            const eventType = otpTypeMap[login_type];
            DeepBI.PingLoginevents(eventType, "success", username);
          } else {
            DeepBI.PingLoginevents("Send_Mobile_OTP", "success", username);
          }
        } else {
          setIsloading(false);
          setErrorWithTimeout(result.msg);
          if (otpTypeMap.hasOwnProperty(login_type)) {
            const eventType = otpTypeMap[login_type];
            DeepBI.PingLoginevents(eventType, "failure", username, null, result.msg);
          } else {
            DeepBI.PingLoginevents("Send_Mobile_OTP", "failure", username, null, result.msg);
          }
        }
      } catch (error) {
        console.error("Error generating OTP:", error);
        // You may want to display an error message to the user here
      }
    }
  };

  const handleOptVerify = async () => {
    const otpValue = otp.join(""); // Combine OTP array into a string

    // Check if any OTP field is empty
    if (otp.includes("")) {
      setErrorWithTimeout("Please enter valid OTP.");
      return;
    } else {
      setErrorWithTimeout(""); // Clear any previous error
    }

    setIsloading(true);

    const body = {
      email_id: `${countryCode.replace(/\+/g, "")}${mobileNumber}`, // Dynamically using countryCode and mobileNumber
      otp: otpValue
    };

    let username = `${countryCode.replace(/\+/g, "")}${mobileNumber}`;
    try {
      const result = await fetchGenerateOtp(generateJWT(body), "verify-otp");
      if (result.status === 1) {
        const resData = get(result, ["data"]);

        setIsloading(false);
        setShowSuccessLogin(true);
        handleResponseAndTrack(result);
        DeepBI.PingLoginevents("Mobile_OTP_verification", "success", username, resData);
        handleRedirectOrReload(1000);
        setShowMobileInput(false);
        setShowOtp(false);
        if (onHideLogin) {
          onHideLogin();
        }
      } else {
        DeepBI.PingLoginevents("Mobile_OTP_verification", "failure", username, null, result.msg);
        DeepBI.PingLoginevents("user_login", "failure");

        setIsloading(false);
        setErrorWithTimeout(result.msg);
        setShowOtp(true);
      }
    } catch (e) {
      const error = { status: 2, msg: "something went wrong" };
      return error;
    }
  };

  const handleOptVerifyEmail = async () => {
    const otpValue = otp.join(""); // Combine OTP array into a string

    // Check if any OTP field is empty
    if (otp.includes("")) {
      setErrorWithTimeout("Please enter valid OTP.");
      return;
    } else {
      setErrorWithTimeout(""); // Clear any previous error
    }
    setIsloading(true);

    const body = {
      email_id: email, // Dynamically using countryCode and mobileNumber
      otp: otpValue
    };

    try {
      const response = await fetchGenerateOtp(generateJWT(body), "verify-otp");
      if (response.status === 1) {
        const resData = get(response, ["data"]);

        setIsloading(false);
        setShowSuccessLogin(true);
        handleResponseAndTrack(response);
        DeepBI.PingLoginevents("Email_OTP_verification", "success", email, resData);
        handleRedirectOrReload(1000);
        setShowEmailInput(false);
        setShowOtpEmail(false);
        if (onHideLogin) {
          onHideLogin();
        }
      } else {
        DeepBI.PingLoginevents("Email_OTP_verification", "failure", email, null, response.msg);
        DeepBI.PingLoginevents("user_login", "failure");
        setIsloading(false);
        setErrorWithTimeout(response.msg);
        setShowOtpEmail(true);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      // Display error to the user if needed
    }
  };

  const handleOTPChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      setOTP(prevOTP => {
        const updatedOTP = [...prevOTP];
        updatedOTP[index] = value;
        return updatedOTP;
      });

      // Move focus to the next input field
      if (value !== "" && index < 5) {
        document.getElementById(`otp${index + 1}`).focus();
      }
    }
  };

  const handleOTPKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      document.getElementById(`otp${index - 1}`).focus();
    }
  };

  const handleMobileNumberChange = e => {
    // Validate mobile number: Allow only numeric input
    const mobileNumberValue = e.target.value.trim();
    if (/^\d*$/.test(mobileNumberValue)) {
      setMobileNumber(mobileNumberValue);
    }
  };

  const handleEmailChange = e => {
    setEmail(e.target.value);
    setErrorWithTimeout("");
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };

  const validateEmail = () => {
    // Basic email validation using a regular expression
    // eslint-disable-next-line no-useless-escape
    if (!email || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
      setErrorWithTimeout("Please enter a valid email.");
      return false;
    }
    return true;
  };

  const handleCheckEmail = async () => {
    if (validateEmail()) {
      setIsloading(true);

      const body = {
        email_id: email,
        login_type: "pwd"
      };

      try {
        const response = await fetchGenerateOtp(generateJWT(body), "verify-user"); // Replace "login-endpoint" with the actual endpoint
        if (response.status === 1) {
          setIsloading(false);
          if (response.exist) {
            setShowEmailInput(true);
            setShowEmailwidget(false);
          } else {
            setShowOtpEmail(true);
            setShowEmailwidget(false);
            setNewUser(true);
            DeepBI.PingLoginevents("Get_Email_OTP", "success", email);
          }
        } else {
          setIsloading(false);
          setShowEmailwidget(true);
          setErrorWithTimeout(response.msg);
          DeepBI.PingLoginevents("Get_Email_OTP", "failure", email, null, response.msg);
        }

        // Handle success: Redirect or perform other actions after login
      } catch (error) {
        console.error("Error during login:", error);
        // Handle error, e.g., show an error message to the user
      }
    } else {
      console.error("Invalid email format"); // Handle invalid email scenario
    }
  };

  const handlePasswordLogin = async () => {
    if (!validateEmail()) {
      setErrorWithTimeout("Invalid email format.");
      return;
    }

    if (!password) {
      setErrorWithTimeout("Please enter password.");
      return;
    }

    setIsloading(true);

    const body = {
      email_id: email,
      password: password
    };

    try {
      const result = await fetchGenerateOtp(generateJWT(body), "login"); // Replace "login-endpoint" with the actual endpoint
      if (result.status === 1) {
        const resData = get(result, ["data"]);
        setIsloading(false);
        setShowSuccessLogin(true);
        handleResponseAndTrack(result);
        DeepBI.PingLoginevents("Password_verification", "success", email, resData);
        handleRedirectOrReload(1000);
        setShowEmailInput(false);
        setShowOtpEmail(false);
        if (onHideLogin) {
          onHideLogin();
        }
      } else {
        DeepBI.PingLoginevents("Password_verification", "failure", email, null, result.msg);
        DeepBI.PingLoginevents("user_login", "failure");
        setIsloading(false);
        setShowOtpEmail(false);
        setShowEmailInput(true);
        setErrorWithTimeout(result.msg);
      }

      // Handle success: Redirect or perform other actions after login
    } catch (error) {
      console.error("Error during login:", error);
      // Handle error, e.g., show an error message to the user
    }
  };

  const handleResponseAndTrack = response => {
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 1);
    const resData = get(response, ["data"]);
    const guid = get(response, ["data", "guid"]);
    const VuID = get(response, ["data", "vuid"]);
    const evSS = get(response, ["data", "mainSession", "sessionId"]);
    const exSS = get(response, ["data", "secureSession", "sessionId"]);
    const email = get(response, ["data", "email"]);
    const userType = get(response, ["data", "userType"]);
    cookies.set("ev-guid", guid, { path: "/", domain: ".vikatan.com", expires: nextYear });
    cookies.set("ev_ss", evSS, { path: "/", domain: ".vikatan.com", expires: nextYear });
    cookies.set("ex_ss", exSS, { path: "/", domain: ".vikatan.com", expires: nextYear });
    cookies.set("vuid", VuID, { path: "/", domain: ".vikatan.com", expires: nextYear });
    cookies.set("vikatan[auth_id]", bin2hex(email), { path: "/", domain: ".vikatan.com", expires: nextYear });
    cookies.set("vikatan[utype]", userType, { path: "/", domain: ".vikatan.com", expires: nextYear });

    global.localStorage.setItem("evolok:ev_session", JSON.stringify(resData));
    DeepBI.PingLoginevents("user_login", "success", email, resData);

    const userId = intoVal(VuID);
    if (global.webengage) {
      global.webengage.user.setAttribute("User_Id", userId);
      global.webengage.user.login(userId);
    }
  };

  return showPopup ? (
    <div className={`${styles["popup-wrapper"]} ${Isloginpage ? styles["ispagetrue"] : styles["ispagefalse"]}`}>
      {Isloading && (
        <div className={styles["loader-wrapper"]}>
          <img alt="Vikatan" src={assetify(loader)} className={styles["loader"]} />
        </div>
      )}
      <div className={`${styles["page-popup-wrapper-section"]}`}>
        <div className={`${styles["popup-wrapper-section"]}`}>
          <div
            className={` ${!Isloginpage ? styles["close-div"] : styles["close-btn-hide"]}`}
            onClick={() => handleClosePopup()}
          >
            <Icon type="close" />
          </div>
          <div className={`${styles["login-wrapper"]}`}>
            <div className={`${styles["login-images-sec"]}`}>
              <img
                src="https://media.vikatan.com/vikatan/2023-07/44582821-d1d8-40a7-a7bb-4fe4da84cf88/login_popup_image.png?q=75&w=226"
                alt=""
              />
            </div>
            <div className={styles["base"]}>
              {/* Login option for mobile & Email Starts */}
              {showMobileEmail ? (
                <div className={styles["login-main-mobile"]}>
                  <h2 className={styles["title"]}>Log in or Signup</h2>
                  <div className={styles["login-cta"]}>
                    <div className={styles["button-filled"]} onClick={() => handleUserMobile()}>
                      <Icon type="phone" />
                      <span className={styles["button-filled-span"]}>Continue with Mobile number</span>
                    </div>
                    <div className={styles["button-outline"]} onClick={() => handleUserEmail()}>
                      <Icon type="mail" />
                      <span className={styles["button-outline-span"]}>Continue with Email ID</span>
                    </div>
                    <div>
                      <GmailSignin
                        setIsloading={setIsloading}
                        setErrorWithTimeout={setErrorWithTimeout}
                        setShowEmailMobileInput={setShowEmailMobileInput}
                        setShowSuccessLogin={setShowSuccessLogin}
                        generateJWT={generateJWT}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              {/* Login option for mobile & Email Ends */}
              {/* Login with mobile No Starts */}
              {ShowMobileInput ? (
                <div className={`${styles["get-input-outer"]} ${styles["common-datawall-grid"]}`}>
                  <div
                    className={styles["back-button"]}
                    onClick={() => handleBack("showMobileEmail", "ShowMobileInput")}
                  >
                    <Icon type="arrow-left" />
                  </div>
                  <h1 className={styles["subtitle"]}>
                    உங்கள் <span>Mobile</span> நம்பரை டைப் செய்க
                  </h1>
                  <div className={styles["paywall-content"]}>
                    <div className={styles["paywall-special-grid"]}>
                      <div className={styles["email-section"]}>
                        <div className={styles["mobile-num-section"]}>
                          <div className={styles["input-grid"]}>
                            <div className={styles["input-style"]}>
                              <select
                                value={countryCode}
                                onChange={updateCountryCode}
                                onFocus={() => setIsDropdownOpen(true)} // Open dropdown
                                onBlur={() => setIsDropdownOpen(false)} // Hide code when dropdown is closed
                                className={styles["custom-select"]}
                              >
                                {countryCodes.map(country => (
                                  <option key={country.alpha2} value={country.code}>
                                    {isDropdownOpen
                                      ? `${country.name} (${country.code})` // Show name and code when open
                                      : country.code}{" "}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className={`${styles["input-style"]} ${styles["input-style-mobile"]}`}>
                              <input
                                ref={mobileNumberInputRef}
                                type="tel"
                                value={mobileNumber}
                                onChange={handleMobileNumberChange}
                                placeholder="Mobile Number"
                              />
                            </div>
                          </div>
                        </div>
                        {error ? (
                          <div className={`${styles["error-msg"]} ${styles["limit-error-msg"]}`}>{error}</div>
                        ) : (
                          <div className={styles["empty-div"]}>&nbsp;</div>
                        )}
                        <button
                          className={`${styles["button-filled"]} ${styles["cta"]}`}
                          onClick={() => handleSubmitUser()}
                        >
                          Send OTP
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* Login with mobile No Ends */}
              {/* Login with Email Starts */}

              {ShowEmailwidget ? (
                <div
                  className={`${styles["get-input-outer"]} ${styles["common-datawall-grid"]} ${
                    styles["login-email-wrapper"]
                  }`}
                >
                  <div
                    className={styles["back-button"]}
                    onClick={() => handleBack("showMobileEmail", "ShowEmailwidget")}
                  >
                    <Icon type="arrow-left" />
                  </div>
                  <h1 className={styles["subtitle"]}>உங்கள் Email ID யை டைப் செய்க</h1>
                  <div className={styles["paywall-content"]}>
                    <div className={styles["paywall-special-grid"]}>
                      <div className={styles["email-section"]}>
                        <div className={`${styles["login-email"]}`}>
                          <div className={`${styles["input-style"]}`}>
                            <input type="text" value={email} onChange={handleEmailChange} placeholder="Email ID" />
                          </div>
                        </div>
                        {error ? (
                          <div className={`${styles["error-msg"]} ${styles["limit-error-msg"]}`}>{error}</div>
                        ) : (
                          <div className={styles["empty-div"]}>&nbsp;</div>
                        )}
                        <div className={styles["email-cta"]}>
                          <button
                            className={`${styles["button-filled"]} ${styles["cta"]}`}
                            onClick={() => handleCheckEmail()}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {ShowEmailInput ? (
                <div
                  className={`${styles["get-input-outer"]} ${styles["common-datawall-grid"]} ${
                    styles["login-email-wrapper"]
                  }`}
                >
                  <div
                    className={styles["back-button"]}
                    onClick={() => handleBack("ShowEmailwidget", "ShowEmailInput")}
                  >
                    <Icon type="arrow-left" />
                  </div>
                  <h1 className={styles["subtitle"]}>உங்கள் Email ID யை டைப் செய்க</h1>
                  <div className={styles["paywall-content"]}>
                    <div className={styles["paywall-special-grid"]}>
                      <div className={styles["email-section"]}>
                        <div className={`${styles["login-email"]}`}>
                          <div className={`${styles["input-style"]}`}>
                            <input
                              type="email"
                              value={email}
                              onChange={handleEmailChange}
                              placeholder="Email ID"
                              readOnly
                            />
                          </div>
                          <div className={`${styles["pwd-view"]}`}>
                            <div className={`${styles["input-style"]}`}>
                              <input
                                type={inputType}
                                value={password}
                                onChange={handlePasswordChange}
                                placeholder="Password"
                              />
                            </div>
                            <div className={styles["view-pwd"]} view- onClick={togglePasswordVisibility}>
                              <img
                                src={togglePasswordImage}
                                alt="Toggle Password Visibility"
                                className="toggle_password"
                              />
                            </div>
                          </div>
                        </div>
                        {error ? (
                          <div className={`${styles["error-msg"]} ${styles["limit-error-msg"]}`}>{error}</div>
                        ) : (
                          <div className={styles["empty-div"]}>&nbsp;</div>
                        )}
                        <div className={styles["email-cta"]}>
                          <button
                            className={`${styles["button-outline"]} ${styles["cta"]}`}
                            onClick={() => handleUserEmailOtp()}
                          >
                            Get OTP
                          </button>
                          <span>OR</span>
                          <button
                            className={`${styles["button-filled"]} ${styles["cta"]}`}
                            onClick={() => handlePasswordLogin()}
                          >
                            Login
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* Login with Email No Ends */}
              {/* Mobile OTP  Starts */}
              {ShowOtp ? (
                <div className={`${styles["show-otp-outer"]} ${styles["common-datawall-grid"]}`}>
                  <div className={styles["back-button"]} onClick={() => handleBack("ShowMobileInput", "ShowOtp")}>
                    <Icon type="arrow-left" />
                  </div>
                  <div className={styles["otp-section"]}>
                    <p className={styles["subtitle"]}>
                      உங்களின் {mobileNumber} என்ற எண்ணுக்கு OTP இன்னும் 60 நொடிகளில்
                      {countryCode !== "+91" && " WhatsApp-ல்"} வந்துசேரும்
                    </p>

                    <div>
                      {/* <div className={styles["edit-field"]}>
                      <input type="text" placeholder="9176484644" className={styles["input-style"]} />
                      <Icon type="edit" />
                    </div> */}

                      <div className={styles["otp-number"]}>
                        <div className={styles["input-style"]}>
                          {otp.map((value, index) => (
                            <input
                              key={index}
                              type="tel"
                              maxLength="1"
                              id={`otp${index}`}
                              value={value}
                              onChange={e => handleOTPChange(index, e.target.value)}
                              onKeyDown={e => handleOTPKeyDown(e, index)}
                              ref={index === 0 ? otpInputRefs : null}
                              autoComplete="one-time-code"
                            />
                          ))}
                        </div>
                      </div>

                      <div className={styles["otp-mail"]}>
                        <div className={styles["otp-content"]}>
                          <p className={styles["otp-sec"]}>OTP வரவில்லையா? மீண்டும் பெற</p>
                          <p className={styles["time"]}>{`00:${counter < 10 ? `0${counter}` : counter}`}</p>
                        </div>

                        {/* {!isDisabled && ( */}
                        {error ? (
                          <div className={`${styles["error-msg"]} ${styles["limit-error-msg"]}`}>{error}</div>
                        ) : (
                          ""
                        )}

                        <div
                          className={`${styles["otp-options"]} ${
                            !isDisabled ? styles["otp-options"] : styles["otp-option-disable"]
                          }`}
                        >
                          <div className={styles["whatsapp"]} onClick={() => handleSubmitUser("whatsapp")}>
                            <Icon type="whatsapp-icon" />
                            <span className={styles["whatsapp-span"]}>whatsapp</span>
                          </div>
                          {countryCode === "+91" && (
                            <div className={styles["sms"]} onClick={() => handleSubmitUser("resend")}>
                              <Icon type="textsms" />
                              <span className={styles["sms-span"]}>SMS</span>
                            </div>
                          )}
                        </div>
                        {/* )} */}
                      </div>
                      <div className={styles["cta-mail"]}>
                        <button
                          className={`${styles["button-filled"]} ${styles["cta"]}`}
                          onClick={() => handleOptVerify()}
                        >
                          Verify OTP
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* Mobile OTP  Ends */}
              {/* Email OTP  Starts */}
              {ShowEmailOtp ? (
                <div className={`${styles["show-otp-outer"]} ${styles["common-datawall-grid"]}`}>
                  <div className={styles["back-button"]} onClick={() => handleBack("ShowEmailInput", "ShowEmailOtp")}>
                    <Icon type="arrow-left" />
                  </div>
                  <div className={styles["otp-section"]}>
                    <p className={styles["subtitle"]}>
                      உங்களின் {email} என்ற மின்னஞ்சலுக்கு OTP இன்னும் 60 நொடிகளில் வந்துசேரும்
                    </p>
                    <div>
                      {/* <div className={styles["edit-field"]}>
                      <input type="text" placeholder="9176484644" className={styles["input-style"]} />
                      <Icon type="edit" />
                    </div> */}
                      <div className={styles["otp-number"]}>
                        <div className={styles["input-style"]}>
                          {otp.map((value, index) => (
                            <input
                              key={index}
                              type="tel"
                              maxLength="1"
                              id={`otp${index}`}
                              value={value}
                              onChange={e => handleOTPChange(index, e.target.value)}
                              onKeyDown={e => handleOTPKeyDown(e, index)}
                              ref={index === 0 ? otpInputRefs : null}
                              autoComplete="one-time-code"
                            />
                          ))}
                        </div>
                      </div>
                      <div className={styles["resent-otp"]}>
                        <p
                          className={`${styles["resend"]} ${!isDisabled ? styles["resend"] : styles["resend-disable"]}`}
                          onClick={() => handleUserEmailOtp()}
                        >
                          RESEND OTP
                        </p>
                        <p className={styles["time"]}>{`00:${counter < 10 ? `0${counter}` : counter}`}</p>
                      </div>
                      {error ? (
                        <div className={`${styles["error-msg"]} ${styles["limit-error-msg"]}`}>{error}</div>
                      ) : (
                        ""
                      )}

                      <div className={styles["cta-mail"]}>
                        <button
                          className={`${styles["button-filled"]} ${styles["cta"]}`}
                          onClick={() => handleOptVerifyEmail()}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* Email OTP  Ends */}

              {ShowSuccessLogin && (
                <div className={styles["login-success"]}>
                  <div className={styles["login-success-img"]}>
                    <img src="https://gumlet.assettype.com/vikatan/2024-11-19/olbf3a52/login-success.png" alt="" />
                  </div>
                  Login Successful!
                </div>
              )}
            </div>
          </div>
        </div>
        <VikatanLoginFooter />
      </div>
    </div>
  ) : null;
};

// Add props validation
MobileLogin.propTypes = {
  onClose: PropTypes.func.isRequired,
  Isloginpage: PropTypes.bool,
  jwtKey: PropTypes.stringify,
  onHideLogin: PropTypes.func
};

function mapStateToProps(state) {
  return { jwtKey: get(state, ["qt", "config", "JwtKey"]) };
}

export default connect(mapStateToProps)(MobileLogin);
