import React, { useState, useEffect } from "react";
import { string, object, number, array } from "prop-types";
import { WithLazy } from "@quintype/components";
import { get } from "lodash";
import styles from "./styles.m.css";
import Media from "react-media";
import { DfpAd } from "../../atoms/dfp-ad";
// import { DeepBI } from "../../../integrations";
import { getParentSection } from "../../../data/section";
import { connect } from "react-redux";
import { isPremium } from "../../../data/story";

const proptypeSet1 = {
  mobile: string,
  adtype: string,
  story: object,
  storyId: string,
  placement: string,
  className: string,
  DesktopAdtype: string,
  MobileAdtype: string,
  unit: string,
  class: string
};

const proptypeSet3 = { adId: string, adSize: array, minHeight: number, adDivId: string, class: string, story: object };

const VikatanResponsiveContainer = ({ adtype, placement, storyId = "", className, story }) => {
  const [userType, setUserType] = useState("F");
  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      user && setUserType(user.userType);
    });
  }, []);

  const generateAdSlotName = index => {
    if (index >= 1 && index <= 3) {
      return `MID${index}`;
    } else if (index >= 4 && index <= 5) {
      return `BTF${index - 3}`;
    } else if (index >= 5) {
      return "BTF2";
    } else {
      return `MID1`;
    }
  };

  return (
    <React.Fragment>
      {userType === "P" || userType === "T" || userType === "R" ? (
        <div />
      ) : (
        <WithLazy margin="300px">
          {() => (
            <div className={`vikatan-top-ad-width ad`}>
              <div className="ad-container vikatan-top-ad">
                <Media query="(min-width:768px)">
                  <div className={`desktop ad_300_250`}>
                    <PGAdwithAd
                      adId={`Vikatan2023_Desktop_AP_${generateAdSlotName(adtype)}_336x280`}
                      adSize={[[336, 280], [300, 250]]}
                      minHeight={280}
                      adDivId={`div-ad-desk-3849069-1690281136-3_${generateAdSlotName(adtype)}_${adtype}_${storyId}`}
                      class=""
                      story={story}
                    />
                  </div>
                </Media>
                {adtype !== 1 && (
                  <Media query="(max-width:768px)">
                    <div className={`mobile ad_300_250`}>
                      <PGAdwithAd
                        adId={`Vikatan2023_Mobile_AP_${generateAdSlotName(adtype - 1)}_336x280`}
                        adSize={[[336, 280], [300, 250]]}
                        minHeight={280}
                        adDivId={`div-ad-desk-3849069-1690281338-0-${generateAdSlotName(adtype - 1)}_${adtype -
                          1}_${storyId}`}
                        class=""
                        story={story}
                      />
                    </div>
                  </Media>
                )}
              </div>
            </div>
          )}
        </WithLazy>
      )}
    </React.Fragment>
  );
};

VikatanResponsiveContainer.propTypes = proptypeSet1;

const VikataninterstitialBase = props => {
  const story = get(props, ["getStory"], {});

  return (
    <div style={{ height: "0px" }}>
      <PGAdwithAd
        adId={`Vikatan_Interstitial_1x1`}
        adSize={[[1, 1]]}
        minHeight={0}
        adDivId={`div-ad-desk-3849069-1637135903666-0`}
        class=""
        story={story}
      />
      <PGAdwithAd
        adId={`Vikatan_HVR_1x1`}
        adSize={[[1, 1]]}
        minHeight={0}
        adDivId={`div-ad-desk-3849069-1690281988-0`}
        class=""
        story={story}
      />
      <PGAdwithAd
        adId={`1x1`}
        adSize={[[1, 1]]}
        minHeight={0}
        adDivId={`div-ad-desk-3849069-1733918289050-0`}
        class=""
        story={story}
      />
    </div>
  );
};

VikataninterstitialBase.propTypes = proptypeSet1;

const Vikatanveveadslot = () => {
  const [userType, setUserType] = useState("F");
  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      user && setUserType(user.userType);
    });
  }, []);
  return (
    <React.Fragment>
      {userType === "P" || userType === "T" || userType === "R" ? (
        <div />
      ) : (
        <div className="vikatan-top-ad-width ad-container-outer">
          <div className="ad-container ad-320x100-veve">
            <div className={styles["veve-link-text"]}>Sponsored links</div>
            <Media query="(min-width:768px)">
              <div className="desktop">
                <PGAdwithAd
                  adId={`VeVe_Vikatan2024_Desktop_320x100`}
                  adSize={[[320, 100]]}
                  minHeight={100}
                  adDivId={`div-ad-desk-3849069-1712226878-0`}
                  class=""
                />
              </div>
            </Media>
            <Media query="(max-width:768px)">
              <div className="mobile">
                <PGAdwithAd
                  adId={`VeVe_Vikatan2024_Mobile_320x100`}
                  adSize={[[320, 100]]}
                  minHeight={100}
                  adDivId={`div-ad-mob-3849069-17122039303983-0`}
                  class=""
                />
              </div>
            </Media>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

Vikatanveveadslot.propTypes = proptypeSet1;

const VikatanResponsiveHeader = () => {
  return (
    <div styleName="vikatan-top-ad-width ad">
      <div styleName="ad-container vikatan-top-ad">
        <div styleName="desktop hidden-mobile">
          <Media query="(min-width:768px)">
            <DfpAd adtype={`Vikatan_Desktop_AP_ATF_728x90`} placement="home" className="ad-728x90" hidetitle={true} />
          </Media>
        </div>
        <div styleName="mobile hidden-desktop">
          <Media query="(max-width:768px)">
            <DfpAd adtype={`Vikatan_Mobile_AP_TOP_320x100`} placement="all" className="ad-320x100" hidetitle={true} />
          </Media>
        </div>
      </div>
    </div>
  );
};

VikatanResponsiveHeader.propTypes = proptypeSet1;

const VikatanEndArticle = (adtype, placement, storyId = "", className, story) => {
  const [userType, setUserType] = useState("F");
  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      user && setUserType(user.userType);
    });
  }, []);
  return (
    <React.Fragment>
      {userType === "P" || userType === "T" || userType === "R" ? (
        <div />
      ) : (
        <div className={styles["vikatan-end-ad"]}>
          <div styleName="ad-container vikatan-top-ad">
            <Media query="(min-width:768px)">
              <div styleName="desktop">
                <PGAdwithAd
                  adId={`Vikatan2023_Desktop_AP_BTF_468x60`}
                  adSize={[[468, 60]]}
                  minHeight={60}
                  adDivId={`div-ad-desk-3849069-1690457993-1_${storyId}`}
                  class="ad-468x60"
                  story={story}
                />
              </div>
            </Media>
            <Media query="(max-width:768px)">
              <div styleName="mobile">
                <PGAdwithAd
                  adId={`Vikatan2023_Mobile_AP_BTF_320x100`}
                  adSize={[[320, 100]]}
                  minHeight={100}
                  adDivId={`div-ad-desk-3849069-1690281338-6_${storyId}`}
                  class="ad-320x100"
                />
              </div>
            </Media>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

VikatanEndArticle.propTypes = proptypeSet1;

const VikatanBetweenArticle = (adtype, placement, story) => {
  return (
    <WithLazy margin="300px">
      {() => (
        <div styleName="vikatan-top-ad-width ad">
          <div styleName="ad-container vikatan-top-ad">
            <Media query="(min-width:768px)">
              <div className="desktop article-between">
                <div className="container article-btw-ad">
                  <PGAdwithAd unit="Vikatan_article_leaderboard_mid" class="between-article" story={story} />
                </div>
              </div>
            </Media>
            <Media query="(max-width:768px)">
              <div className="mobile article-between">
                <PGAdwithAd unit="Vikatan_mobile_article_leaderboard_top" class="between-article" story={story} />
              </div>
            </Media>
          </div>
        </div>
      )}
    </WithLazy>
  );
};

VikatanBetweenArticle.propTypes = proptypeSet1;

const VikatanSticky = (placement, storyId = "home", story) => {
  const [userType, setUserType] = useState("F");
  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      user && setUserType(user.userType);
    });
  }, []);
  return (
    <React.Fragment>
      {userType === "P" || userType === "T" || userType === "R" ? (
        <div />
      ) : (
        <div>
          <Media query="(max-width:768px)">
            {matches =>
              matches && (
                <div className={styles["sticky-ad"]}>
                  <PGAdwithAd
                    adId={`Vikatan2023_Mobile_Sticky_320x50`}
                    adSize={[[320, 50]]}
                    minHeight={50}
                    adDivId={`div-ad-desk-3849069-1690281477-0_${storyId}`}
                    className="hidden-desktop"
                    story={placement.story}
                  />
                </div>
              )
            }
          </Media>
        </div>
      )}
    </React.Fragment>
  );
};

const VikatanDesktopSticky = (placement, storyId = "home", story) => {
  const [userType, setUserType] = useState("F");
  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      user && setUserType(user.userType);
    });
  }, []);
  return (
    <React.Fragment>
      {userType === "P" || userType === "T" || userType === "R" ? (
        <div />
      ) : (
        <div>
          <Media query="(min-width:768px)">
            {matches =>
              matches && (
                <div>
                  <AdExchangeScript />
                  <div className={styles["sticky-ad-desktop"]}>
                    <PGAdwithAd
                      adId={`Vikatan2024_Desktop_Sticky_Multisize_970x90`}
                      adSize={[[970, 90]]}
                      minHeight={90}
                      adDivId={`div-ad-desk-3849069-1717581619`}
                      className=""
                    />
                  </div>
                </div>
              )
            }
          </Media>
        </div>
      )}
    </React.Fragment>
  );
};

const VikatanStickyTop = (placement, storyId = "home", story) => {
  return (
    <div>
      <Media query="(max-width:768px)">
        <div className={styles["sticky-ad-top"]}>
          <PGAdwithAd
            adId={`Vikatan2024_Mobile_StickyTOP_320x50_USmarket`}
            adSize={[[320, 50]]}
            minHeight={50}
            adDivId={`div-ad-desk-3849069-1697-0_${storyId}`}
            className="hidden-desktop"
            story={placement.story}
          />
        </div>
      </Media>
    </div>
  );
};

VikatanStickyTop.propTypes = proptypeSet1;

const VikatanTowerAd = ({ adtype, placement, story, className }) => {
  return (
    <div className={`${className} ${styles["ad"]}`}>
      <Media query="(min-width:768px)">
        <DfpAd story={story} adtype={adtype} placement={placement} />
      </Media>
    </div>
  );
};

VikatanTowerAd.propTypes = proptypeSet1;

const VikatanHeaderAd = ({ adtype, placement, story, className }) => {
  return (
    <div className={`${className} ${styles["ad"]}`}>
      <Media query="(min-width:768px)">
        <div className={`${styles["ad-top-header"]} hidden-mobile`}>
          <PGAdwithAd
            adId={`Vikatan2023_Desktop_HP_Top_428x70`}
            adSize={[[428, 70]]}
            minHeight={70}
            adDivId={`div-ad-desk-3849069-1690809523-0`}
            class=""
          />
        </div>
      </Media>
    </div>
  );
};

VikatanHeaderAd.propTypes = proptypeSet1;

const VikatanCollectionResponsive = ({ DesktopAdtype, MobileAdtype, placement, adtype = 1 }) => {
  return (
    <WithLazy margin="300px">
      {() => (
        <div className="vikatan-top-ad-width ad">
          <div className="ad-container vikatan-top-ad">
            <Media query="(min-width:768px)">
              <div className="desktop">
                <PGAdwithAd
                  adId={DesktopAdtype}
                  adSize={[[970, 90], [728, 90]]}
                  minHeight={90}
                  adDivId={`div-ad-desk-3849069-16179-0${adtype}`}
                  class="ad-728x90"
                />
              </div>
            </Media>
            <Media query="(max-width:768px)">
              <div className="mobile">
                <PGAdwithAd
                  adId={MobileAdtype}
                  adSize={[[320, 100]]}
                  minHeight={100}
                  adDivId={`div-ad-desk-3849069-1690280-0${adtype}`}
                  class="ad-320x100"
                />
              </div>
            </Media>
          </div>
        </div>
      )}
    </WithLazy>
  );
};

VikatanCollectionResponsive.propTypes = proptypeSet1;

const VikatanHomeCollectionResponsive = ({ DesktopAdtype, MobileAdtype, placement, adtype = 1 }) => {
  return (
    <WithLazy margin="300px">
      {() => (
        <div className="vikatan-top-ad-width ad">
          <div className="ad-container vikatan-top-ad">
            <Media query="(min-width:768px)">
              <div className="desktop">
                <PGAdwithAd
                  adId={DesktopAdtype}
                  adSize={[[970, 90], [728, 90]]}
                  minHeight={90}
                  adDivId={`div-ad-desk-3849069-777-01${adtype}`}
                  class="ad-728x90"
                />
              </div>
            </Media>
            <Media query="(max-width:768px)">
              <div className="mobile">
                <PGAdwithAd
                  adId={MobileAdtype}
                  adSize={[[300, 250], [336, 280]]}
                  minHeight={280}
                  adDivId={`div-ad-desk-3849069-85741-0${adtype}`}
                  class="ad_300_250"
                />
              </div>
            </Media>
          </div>
        </div>
      )}
    </WithLazy>
  );
};

VikatanHomeCollectionResponsive.propTypes = proptypeSet1;

const VikatanHomeStaticAdDesktop = () => {
  return (
    <WithLazy margin="300px">
      {() => (
        <div className="vikatan-top-ad-width ad">
          <div className="ad-container vikatan-top-ad">
            <Media query="(min-width:768px)">
              <div className="desktop">
                <PGAdwithAd
                  adId={`Vikatan2023_Desktop_HP_MID1_970x250`}
                  adSize={[[970, 250]]}
                  minHeight={250}
                  adDivId={`div-ad-desk-3849069-1690280771-3`}
                  class="ad-top-bottom-space"
                />
              </div>
            </Media>
          </div>
        </div>
      )}
    </WithLazy>
  );
};

VikatanHomeStaticAdDesktop.propTypes = proptypeSet1;

const VikatanMainResponsive = ({ placement, DesktopAdtype, MobileAdtype }) => {
  let pagetypeUnit = placement === "home" ? "HP" : "SP";
  return (
    <WithLazy margin="300px">
      {() => (
        <div className="vikatan-top-ad-width ad">
          <div className="ad-container vikatan-top-ad">
            <Media query="(min-width:768px)">
              <div className="desktop hidden-mobile">
                {placement === "home" ? (
                  <PGAdwithAd
                    adId={`Vikatan2023_Desktop_${pagetypeUnit}_RightMID_300x250`}
                    adSize={[300, 250]}
                    minHeight={250}
                    adDivId="div-ad-desk-3849069-1690280367-0"
                    class="ad_300_250"
                  />
                ) : (
                  <PGAdwithAd
                    adId={`Vikatan2023_Desktop_${pagetypeUnit}_RightMID_336x280`}
                    adSize={([336, 280], [300, 250])}
                    minHeight={280}
                    adDivId="div-ad-desk-3849069-1690280367-12"
                    class="ad_300_250"
                  />
                )}
              </div>
            </Media>
            <Media query="(max-width:768px)">
              <div className="mobile hidden-desktop">
                <PGAdwithAd
                  adId={`Vikatan2023_Mobile_${pagetypeUnit}_ATF_336x280`}
                  adSize={[[336, 280], [300, 250]]}
                  minHeight={280}
                  adDivId="div-ad-desk-3849069-16902805177-0"
                  class="ad_300_250"
                />
              </div>
            </Media>
          </div>
        </div>
      )}
    </WithLazy>
  );
};

VikatanMainResponsive.propTypes = proptypeSet1;

const VikatanHomeHeader = () => {
  const [userType, setUserType] = useState("F");
  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      user && setUserType(user.userType);
    });
  }, []);
  return (
    <React.Fragment>
      {userType === "P" || userType === "T" || userType === "R" ? (
        <div />
      ) : (
        <div className={styles["header-ad-unit"]} styleName="ad-container vikatan-top-ad sd">
          <div className="desktop hidden-mobile">
            <Media query="(min-width:768px)">
              <PGAdwithAd
                adId={`Vikatan2023_Desktop_HP_TOP_970x90`}
                adSize={[[970, 90], [728, 90]]}
                minHeight={90}
                adDivId="div-ad-desk-3849069-1690280367-2"
                class="ad-728x90"
              />
            </Media>
          </div>
          <div className="mobile hidden-desktop">
            <Media query="(max-width:768px)">
              <PGAdwithAd
                adId={`Vikatan2023_Mobile_HP_TOP_320x100`}
                adSize={[320, 100]}
                minHeight={100}
                adDivId="div-ad-desk-3849069-16902805178-0"
                class="ad-320x100"
              />
            </Media>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

VikatanHomeHeader.propTypes = proptypeSet1;

const VikatanSectionHeader = () => {
  const [userType, setUserType] = useState("F");
  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      user && setUserType(user.userType);
    });
  }, []);
  return (
    <React.Fragment>
      {userType === "P" || userType === "T" || userType === "R" ? (
        <div />
      ) : (
        <div className={styles["header-ad-unit"]} styleName="ad-container vikatan-top-ad">
          <div className="desktop hidden-mobile">
            <Media query="(min-width:768px)">
              <PGAdwithAd
                adId={`Vikatan2023_Desktop_SP_ATF_970x90`}
                adSize={[[970, 90], [728, 90]]}
                minHeight={90}
                adDivId="div-ad-desk-3849069-1690280998-2"
                class="ad-728x90"
              />
            </Media>
          </div>
          <div className="mobile hidden-desktop">
            <Media query="(max-width:768px)">
              <PGAdwithAd
                adId={`Vikatan2023_Mobile_SP_TOP_320x100`}
                adSize={[[320, 100]]}
                minHeight={100}
                adDivId="div-ad-desk-3849069-1690280872-0"
                class="ad-320x100"
              />
            </Media>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

VikatanSectionHeader.propTypes = proptypeSet1;

const VikatanAsidebar = () => {
  return (
    <div>
      <Media query="(min-width:768px)">
        <PGAdwithAd unit="Vikatan_article_mrec_top" class="ad_300_250" />
      </Media>

      <div className={`${styles["ad-halfpage"]} hidden-mobile`}>
        <PGAdwithAd unit="Vikatan_article_sidebar_sticky" class="ad-300x600" />
      </div>
    </div>
  );
};

VikatanAsidebar.propTypes = proptypeSet1;

const VikatanStoryHeader = () => {
  const [userType, setUserType] = useState("F");
  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      user && setUserType(user.userType);
    });
  }, []);
  return (
    <div styleName="vikatan-top-ad-width ad">
      <div styleName="ad-container vikatan-top-ad">
        {userType === "P" || userType === "T" || userType === "R" ? (
          <div />
        ) : (
          <div className={`desktop hidden-mobile`}>
            <Media
              query="(min-width: 768px)"
              render={() => (
                <div className={`${styles["header-ad-unit"]}`}>
                  <PGAdwithAd
                    adId={`Vikatan2023_Desktop_AP_ATF_970x90`}
                    adSize={[[970, 90], [728, 90]]}
                    minHeight={90}
                    adDivId="div-ad-desk-3849069-1690281136-4"
                    class="ad-728x90"
                  />
                </div>
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};
VikatanStoryHeader.propTypes = proptypeSet1;

const VikatanGalleryArticle = (adtype, placement, story, className) => {
  return (
    <div styleName="ad-container vikatan-top-ad">
      <PGAdwithAd unit="Vikatan_mobile_article_gallery" class="ad_300_250" />
    </div>
  );
};

VikatanGalleryArticle.propTypes = proptypeSet1;

const PGAdwithAd = props => {
  const [userType, setUserType] = useState("P");
  const randomN = Math.floor(Math.random() * 10000);
  const [randomNum] = useState(randomN);
  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      user && setUserType(user.userType);
    });
  }, []);

  return (
    <React.Fragment>
      {userType === "P" || userType === "T" || userType === "R" ? (
        <div />
      ) : (
        <div className={props.class}>
          <StaticAdUnit
            adId={props.adId} // "/3849069/Vikatan2023_Desktop_HP_RightMID_300x250"
            adSize={props.adSize} // {[300, 250]}
            minHeight={props.minHeight} // {250}
            adDivId={`${props.adDivId}_${randomNum}`}
            story={get(props, ["story"], "")} // "div-ad-desk-3849069-1690280367-0"
          />
        </div>
      )}
    </React.Fragment>
  );
};

PGAdwithAd.propTypes = proptypeSet3;

const AdExchangeScript = () => {
  useEffect(() => {
    // Assuming global.userPromise resolves to the user object
    global.userPromise
      .catch(() => ({})) // Handle any errors and return an empty object
      .then(user => {
        const userType = get(user, ["userType"], "P"); // Get userType or default to "P"

        // Only execute the script if userType is not "P", "T", or "R"
        if (!["P", "T", "R"].includes(userType)) {
          // Dynamically inject the scripts and execute the ad script
          const firstScript = document.createElement("script");
          firstScript.src = "https://bsrv-qa.bidsxchange.com/7242.js";
          document.getElementById("bsrv-7242").appendChild(firstScript);

          const secondScript = document.createElement("script");
          secondScript.async = true;
          secondScript.src = "https://hi-impact.bidsxchange.com/AdCode.js";
          document.getElementById("bsrv-7242").appendChild(secondScript);

          // Ad execution logic
          const initAd = () => {
            window.bsrvtag = window.bsrvtag || { cmd: [] };
            window.bsrvtag.cmd.push(function(adObj) {
              adObj.AdSlot("bsrv-7242");
              adObj.Meta("/bsrvptr659/bsrvplr807/bsrvadu7242/BSRV-AD-vikatan.com-Direct-RSwPB-SLDR-1x1");
              adObj.CacheBuster("%%CACHEBUSTER%%");
              adObj.UserConsent("0");
              adObj.Domain("Vikatan.com");
              adObj.ClickUrl("%%CLICK_URL_UNESC%%");
              adObj.ViewURL("%%VIEW_URL_UNESC%%");
              adObj.Execute();
            });
          };

          // Ensure the ad script runs after loading
          if (window.bsrvtag) {
            initAd();
          } else {
            secondScript.onload = () => {
              initAd();
            };
          }
        }
      });
  }, []);

  return <div id="bsrv-7242" />;
};

const StaticAdUnit = ({ adId, adSize, minHeight, adDivId, story }) => {
  const theme = get(story, ["metadata", "story-attributes", "theme", 0]);
  const paidArticle = story ? isPremium(story) : false;
  const sections = [get(story, ["sections", 0], {})];
  const parentSection = getParentSection(sections[0]);
  if (parentSection) {
    sections.unshift(parentSection);
  }
  const section = get(sections, [0, "name"]) || "";
  const subSection = get(sections, [1, "name"]) || "";

  useEffect(() => {
    if (!adId || !adSize || !adDivId) {
      console.error("adId, adSize, or adDivId is undefined. Ad slot cannot be defined.");
      return;
    }

    const googletag = global.googletag || {};
    window.googletag = window.googletag || { cmd: [] };

    if (!googletag || !googletag.cmd || typeof googletag.cmd.push !== "function") {
      console.error("Google Ad Manager setup is not available. Ad slot cannot be defined.");
      return;
    }

    googletag.cmd.push(function() {
      var adSlot = googletag.defineSlot(`/3849069/${adId}`, adSize, `${adDivId}`).addService(googletag.pubads());
      theme !== undefined && adSlot.setTargeting("theme", theme);
      section !== undefined && adSlot.setTargeting("section", section);
      subSection !== undefined && adSlot.setTargeting("subsection", subSection);
      adSlot.setTargeting("Paid_Article", paidArticle);

      // Set up an event listener for the 'slotRenderEnded' event
      // googletag.pubads().addEventListener("slotRenderEnded", function(event) {
      //   if (event.slot === adSlot) {
      //     const adUnitName = event.slot.getAdUnitPath();
      //     //  const isFilled = event.isEmpty ? 0 : 1; // 0 for unfilled, 1 for filled
      //     //  DeepBI.PingAdRendered(adUnitName, isFilled);
      //     if ("gtag" in window) {
      //       window.gtag("event", "AdRendered", {
      //         event_category: adUnitName,
      //         event_label: window.location.href
      //       });
      //     }
      //   }
      // });

      // googletag.pubads().addEventListener("impressionViewable", function(event) {
      //   if (event.slot === adSlot) {
      //     // This code will be executed when the specified ad slot becomes viewable
      //     const adUnitName = event.slot.getAdUnitPath();
      //     //  DeepBI.PingAdImpression(adUnitName, 1);
      //     if ("gtag" in window) {
      //       window.gtag("event", "AdImpression", {
      //         event_category: adUnitName,
      //         event_label: window.location.href
      //       });
      //     }
      //   }
      // });

      googletag.enableServices();
      googletag.display(`${adDivId}`);
    });
  }, []);

  if (!adDivId) {
    return <div />; // If adDivId is undefined, don't render anything
  }

  return <div id={`${adDivId}`} style={{ minHeight: `${minHeight}px` }} />;
};

StaticAdUnit.propTypes = proptypeSet3;

const VikatanResponsiveContainerLiveblog = ({ adtype, placement, story, className }) => {
  const [userType, setUserType] = useState("F");
  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      user && setUserType(user.userType);
    });
  }, []);
  return (
    <React.Fragment>
      {userType === "P" || userType === "T" || userType === "R" ? (
        <div />
      ) : (
        <WithLazy margin="300px">
          {() => (
            <div className={`vikatan-top-ad-width ad`}>
              <div className="ad-container vikatan-top-ad">
                <Media query="(max-width:768px)">
                  <div className={`mobile ad_300_250`}>
                    <PGAdwithAd
                      adId={`Vikatan2023_Mobile_AP_ATF_336x280`}
                      adSize={[[336, 280], [300, 250]]}
                      minHeight={280}
                      adDivId="div-ad-desk-3849069-1690281338-0"
                      class="ad_300_250"
                    />
                  </div>
                </Media>
              </div>
            </div>
          )}
        </WithLazy>
      )}
    </React.Fragment>
  );
};
VikatanResponsiveContainerLiveblog.propTypes = proptypeSet1;

const AdSenseFluidUnit = ({ layoutKey, slotId }) => {
  const [userType, setUserType] = useState("P");
  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      user && setUserType(user.userType);
    });
  }, []);
  return (
    <React.Fragment>
      {userType === "P" || userType === "T" || userType === "R" ? (
        <div />
      ) : (
        <div>
          <AdSenseStaticFluidUnit layoutKey={layoutKey} slotId={slotId} />
        </div>
      )}
    </React.Fragment>
  );
};

const AdSenseStaticFluidUnit = ({ layoutKey, slotId }) => {
  useEffect(() => {
    // Check if the AdSense script is already included
    if (!document.querySelector('script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]')) {
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
      script.crossOrigin = "anonymous";
      script.onload = () => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      };
      document.head.appendChild(script);
    } else {
      // Initialize ads if the script is already present
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-format="fluid"
      data-ad-layout-key={layoutKey} // "-h6+d+18-dl+ma"
      data-ad-client="ca-pub-2591855995238005"
      data-ad-slot={slotId} // "5035478210"
    />
  );
};

AdSenseStaticFluidUnit.propTypes = {
  layoutKey: string,
  slotId: number
};

AdSenseFluidUnit.propTypes = {
  layoutKey: string,
  slotId: number
};

const VdoAiScript = () => {
  const [userType, setUserType] = useState("P");

  useEffect(() => {
    // Fetch the userType asynchronously
    global.userPromise.catch(() => ({})).then(user => {
      if (user) {
        setUserType(user.userType);
      }
    });
  }, []);

  useEffect(
    () => {
      // Only initialize the script if userType is not "P", "T", or "R"
      if (!["P", "T", "R"].includes(userType)) {
        const script = document.createElement("script");
        script.defer = true;
        script.async = true;
        script.src = `${window.location.protocol}//a.vdo.ai/core/v-vikatan/vdo.ai.js`;

        document.head.appendChild(script);

        return () => {
          document.head.removeChild(script);
        };
      }
    },
    [userType]
  );
  return <div id="v-vikatan" />;
};

const VdoAiHomeScript = () => {
  const [userType, setUserType] = useState("P");

  useEffect(() => {
    // Fetch the userType asynchronously
    global.userPromise.catch(() => ({})).then(user => {
      if (user) {
        setUserType(user.userType);
      }
    });
  }, []);

  useEffect(
    () => {
      // Only initialize the script if userType is not "P", "T", or "R"
      if (!["P", "T", "R"].includes(userType)) {
        const script = document.createElement("script");
        script.defer = true;
        script.async = true;
        script.src = window.location.protocol + "//a.vdo.ai/core/v-vikatan-v1/vdo.ai.js";
        script.setAttribute("data-cfasync", "false");
        document.head.appendChild(script);

        return () => {
          document.head.removeChild(script);
        };
      }
    },
    [userType]
  );
  return <div id="v-vikatan-v1" />;
};

function mapStateToProps(state) {
  return {
    getStory: get(state, ["qt", "data", "story"], null)
  };
}

const Vikataninterstitial = connect(mapStateToProps)(VikataninterstitialBase);

export {
  VikatanResponsiveContainer,
  VikatanResponsiveHeader,
  VikatanEndArticle,
  VikatanBetweenArticle,
  VikatanSticky,
  VikatanTowerAd,
  VikatanHeaderAd,
  VikatanCollectionResponsive,
  VikatanMainResponsive,
  VikatanHomeHeader,
  VikatanSectionHeader,
  VikatanAsidebar,
  VikatanStoryHeader,
  PGAdwithAd,
  VikatanGalleryArticle,
  Vikataninterstitial,
  VikatanResponsiveContainerLiveblog,
  VikatanHomeCollectionResponsive,
  Vikatanveveadslot,
  VikatanStickyTop,
  AdSenseFluidUnit,
  AdExchangeScript,
  VikatanDesktopSticky,
  VdoAiScript,
  VdoAiHomeScript
};
