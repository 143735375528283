import React from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import SevenStory7s from "../seven-story-7s";
import CustomizableStoryCard from "../../cards/customizable-story-card";
import CustomizableStoryCardSchema from "../../cards/customizable-story-card-schema";
import {
  desktop9by5VerticalH5,
  mobile9by5VerticalMobileh3,
  desktop4by3HorizontalH5,
  mobile4by3HorizontalMobileh4,
  mobileNoimgVerticalMobileh4,
  desktop2by1VerticalH5,
  desktop1by1HorizontalH5
} from "../../cards/customizable-story-card/story-card.config";
import { PGAdwithAd } from "../ad-free";
import styles from "./styles.m.css";
import { getStoryHeadline } from "../../utils/utils";

const NineteenStoriesOneAd = ({ collection, accentColor, pagetype = "" }) => {
  let stories = get(collection, ["items"], [])
    .filter(item => item.type === "story")
    .map(item => item.story)
    .filter(story => getStoryHeadline(story) && story.url);
  let schemaInc = 1;
  return (
    <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
      <div
        itemType="http://schema.org/ItemList"
        itemScope
        id="topStories"
        className={`${styles.row} container row test`}
      >
        <div className={styles["desktop-col-3"]}>
          {stories.splice(0, 1).map(story => (
            <CustomizableStoryCardSchema
              key={story.id}
              className={styles["bottom-gap-2"]}
              story={story}
              desktop={Object.assign({}, desktop9by5VerticalH5, { textWrapperPadding: "var(--space1_5) 0 0" })}
              mobile={Object.assign({}, mobile9by5VerticalMobileh3, { textWrapperPadding: "var(--space1) 0 0" })}
              schemaIncrement={schemaInc++}
            />
          ))}
          {stories.splice(0, 2).map(story => (
            <CustomizableStoryCardSchema
              key={story.id}
              className={styles["line-separator"]}
              story={story}
              desktop={desktop4by3HorizontalH5}
              mobile={mobile4by3HorizontalMobileh4}
              schemaIncrement={schemaInc++}
            />
          ))}
          {stories.splice(0, 2).map(story => (
            <CustomizableStoryCardSchema
              key={story.id}
              className={styles["line-separator"]}
              story={story}
              desktop={desktop4by3HorizontalH5}
              mobile={mobileNoimgVerticalMobileh4}
              schemaIncrement={schemaInc++}
            />
          ))}
        </div>
        <div className={styles["desktop-col-2"]}>
          <PGAdwithAd
            adId={`Vikatan2023_Mobile_SP_MID1_336x280`}
            adSize={[[336, 280], [300, 250], [320, 100], [320, 50]]}
            minHeight={280}
            adDivId="div-ad-desk-3849069-1690280872-1"
            class="ad_300_250 hidden-desktop"
          />

          {stories.splice(0, 2).map(story => (
            <CustomizableStoryCardSchema
              key={story.id}
              className={styles["line-separator"]}
              story={story}
              desktop={desktop2by1VerticalH5}
              mobile={mobile4by3HorizontalMobileh4}
              schemaIncrement={schemaInc++}
            />
          ))}
          {stories.splice(0, 1).map(story => (
            <CustomizableStoryCardSchema
              key={story.id}
              className={styles["line-separator"]}
              story={story}
              desktop={desktop2by1VerticalH5}
              mobile={mobileNoimgVerticalMobileh4}
              schemaIncrement={schemaInc++}
            />
          ))}
        </div>
        <div className={styles["desktop-col-3"]}>
          {stories.splice(0, 4).map(story => (
            <CustomizableStoryCard
              key={story.id}
              className={styles["line-separator"]}
              story={story}
              desktop={desktop4by3HorizontalH5}
              mobile={mobileNoimgVerticalMobileh4}
            />
          ))}
          {stories.splice(0, 3).map(story => (
            <CustomizableStoryCard
              key={story.id}
              className={styles["line-separator"]}
              story={story}
              desktop={desktop4by3HorizontalH5}
            />
          ))}
        </div>
        <div className={styles["desktop-col-4"]}>
          <PGAdwithAd
            adId={`Vikatan_mrec_mid_SP`}
            adSize={[[300, 250]]}
            minHeight={250}
            adDivId="div-ad-desk-3849069-64652474-0"
            class="ad_300_250 hidden-mobile"
          />

          <div>
            {stories.splice(0, 4).map(story => (
              <CustomizableStoryCard
                key={story.id}
                className={styles["bottom-gap"]}
                style={{ "--background-color": "var(--white)" }}
                story={Object.assign({}, story, { contributors: null })}
                desktop={Object.assign({}, desktop1by1HorizontalH5, { imageToHeadlineRatio: [4, 13] })}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

NineteenStoriesOneAd.propTypes = {
  accentColor: PropTypes.string,
  collection: SevenStory7s.propTypes.collection,
  pagetype: PropTypes.string
};
NineteenStoriesOneAd.storyLimit = 9;

export default NineteenStoriesOneAd;
